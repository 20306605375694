<template>
  <b-row
    class="invoice-preview"
  >
    <b-col
      cols="12"
    >
      <b-card>
        <h6>Payment Status with Credit Card</h6>
        <span v-if="fetching">
          <table>
            <tr><td>Payment Status</td>
              <td>:
                <b-spinner
                  small
                  type="grow"
                />
              </td>
            </tr>
          </table>
        </span>
        <span v-else>
          <table v-if="isOk">
            <tr><td>Payment Status</td><td>: <b>{{ status }}</b></td></tr>
          </table>
          <table v-else>
            <tr><td>Payment Status</td><td>: <b>{{ status }}</b></td></tr>
          </table>
        </span>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCard, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,
  },
  data() {
    return {
      status: '',
      isOk: false,
      fetching: true,
    }
  },
  mounted() {
    this.paypalStatus()
    const refreshId = setInterval(() => {
      if (this.status) {
        this.fetching = false
        clearInterval(refreshId)
      }
    }, 1000)
  },
  methods: {
    async paypalStatus() {
      this.status = this.$route.query.status
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

</style>
